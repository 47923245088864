import React from 'react';

import AlternatingPhotosRow from 'web/components/alternating_photos_row';

const AlternatingPhotosGrid: React.FC = () => {
  return (
    <div className="about-page__our-standards-alternating-grid">
      <AlternatingPhotosRow imagePath="/img/nettle/about/header.jpg">
        <i className="icon icon-circle-1 about-page__our-standards-icon" />
        <h2 className="about-page__our-standards-grid-header about-page__our-standards-grid-people">
          It Tastes Amazing.
        </h2>
        <p className="about-page__our-standards-grid-copy">
          Because if the flavor’s not there, none of the rest of it matters.
        </p>
        <p className="about-page__our-standards-grid-copy">
          We taste-test everything (it’s a tough job, but somebody’s gotta do it). If it doesn’t
          pass, we won’t carry it.
        </p>
      </AlternatingPhotosRow>

      <AlternatingPhotosRow imagePath="/img/nettle/about/local.jpg">
        <i className="icon icon-circle-2 about-page__our-standards-icon" />
        <h2 className="about-page__our-standards-grid-header about-page__our-standards-grid-planet">
          Local Whenever Possible.
        </h2>
        <p className="about-page__our-standards-grid-copy">
          So you get the freshest stuff with the smallest carbon footprint, among a ton of other
          great reasons. We carry 70% local goods (produced within 250 miles), compared to about 20%
          at Whole Foods. Still, there are things that simply aren’t available locally, so we pick
          thoughtfully sourced imports to ensure you’ve got what you need.
        </p>
      </AlternatingPhotosRow>

      <AlternatingPhotosRow imagePath="/img/nettle/about/supplychain.jpg">
        <i className="icon icon-circle-3 about-page__our-standards-icon" />
        <h2 className="about-page__our-standards-grid-header about-page__our-standards-grid-transparency">
          100% Supply Chain Transparency.
        </h2>
        <p className="about-page__our-standards-grid-copy">
          So you can stop wondering if there’s a sinister backstory behind every item. Most people
          don’t have time to get to know their food producers. So we do it for you. We talk directly
          to our producers, visit their farms, and demand 100% transparency across the entire supply
          chain — from the name of the fishing boat captain to the most obscure ingredient in that
          prepared meal.
        </p>
      </AlternatingPhotosRow>
      <AlternatingPhotosRow imagePath="/img/nettle/about/ingredients.jpg">
        <i className="icon icon-circle-4 about-page__our-standards-icon" />
        <h2 className="about-page__our-standards-grid-header about-page__our-standards-grid-transparency">
          No Shady Ingredients.
        </h2>
        <p className="about-page__our-standards-grid-copy">
          Nobody likes squinting at labels, wondering what’s in their food. We believe in simple,
          real food without weird chemicals or additives. Ingredients should be a short list of
          things you can pronounce. The criteria for ingredients we allow are some of the most
          rigorous in the industry. We don’t have space here to list all the things we don’t permit,
          but reach out if you have specific questions!
        </p>
      </AlternatingPhotosRow>
      <AlternatingPhotosRow imagePath="/img/nettle/about/animals.jpg">
        <i className="icon icon-circle-5 about-page__our-standards-icon" />
        <h2 className="about-page__our-standards-grid-header about-page__our-standards-grid-transparency">
          Animals Live the Good Life.
        </h2>
        <p className="about-page__our-standards-grid-copy">
          So you can eat meat without guilt. The head of our meat program used to be vegetarian,
          which says a lot for the humane standards we set for our meats. No one has higher
          standards for the treatment of animals than we do, and the result is true outdoor access
          for them, vastly better quality, health, and flavor (along with peace of mind!) for you.
          Is it more expensive than factory-farmed? It can be. But the true cost of cheap meat just
          leaves a bad taste in our mouths.
        </p>
      </AlternatingPhotosRow>
      <AlternatingPhotosRow imagePath="/img/nettle/about/employees.jpg">
        <i className="icon icon-circle-6 about-page__our-standards-icon" />
        <h2 className="about-page__our-standards-grid-header about-page__our-standards-grid-transparency">
          Our Employees Aren{"'"}t Just Employees.
        </h2>
        <p className="about-page__our-standards-grid-copy">
          They’re partners with a stake in the business. So you know that everyone up and down the
          line, from associates to the CEO is motivated to make Good Eggs great. We also make it a
          priority to work with suppliers who treat their workers fairly. We believe good food has a
          ripple effect—our personal health, the health of our communities, and ultimately our
          planet. Labor is a critical piece of that vision.
        </p>
      </AlternatingPhotosRow>
    </div>
  );
};

export default AlternatingPhotosGrid;
