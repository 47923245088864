import React from 'react';

const ShortSummary: React.FC = () => {
  return (
    <div className="about-page__our-standards-summary">
      <h1 className="about-page__header">We{"'"}re Picky. Like, really picky.</h1>
      <p className="about-page__our-standards-copy">
        Strict criteria for us means peace of mind for you. Every item we offer must meet our
        uncommonly strict list of Good Food Promises. We talk to producers, visit many of their
        farms and taste-test their products to make sure everything is in line with our standards
        for quality, flavor and ethical business practices.
      </p>
    </div>
  );
};

export default ShortSummary;
