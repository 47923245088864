import React, {FC} from 'react';
import classNames from 'classnames';

const NAV_LIST_ITEMS = [
  {
    slug: 'standards',
    label: 'Food Standards',
  },
  {
    slug: 'sell',
    label: 'Sell Your Goods',
  },
];

interface AboutNavListProps {
  currentSlug: string;
}

const AboutNavList: FC<AboutNavListProps> = ({currentSlug}) => (
  <div className="about-page__column-nav">
    <ul className="about-page__nav-list">
      {NAV_LIST_ITEMS.map(({slug, label}) => (
        <li
          className={classNames('about-page__nav-element', {
            'about-page__nav-element-current': currentSlug === slug,
          })}
          key={slug}
        >
          {currentSlug !== slug ? <a href={`/about/${slug}`}>{label}</a> : label}
        </li>
      ))}
    </ul>
  </div>
);

export default AboutNavList;
