import React from 'react';
import {Helmet} from 'react-helmet-async';

import AboutLayout from 'web/about/components/about_layout';
import AboutNavList from 'web/about/components/about_nav_list';
import MarketLayout, {reducer as marketLayoutReducer} from 'web/components/market_layout';
import {PageType} from 'web/helpers/redux_client';

import AlternatingPhotosGrid from '../components/grid';
import ShortSummary from '../components/short_summary';
import {StoreData} from './controller';

const OurFoodPage: PageType<StoreData> = () => {
  return (
    <MarketLayout disableUpcomingOrdersBanner>
      <Helmet>
        <title>Our Food | Good Eggs</title>
      </Helmet>

      <AboutLayout>
        <div className="about-page__column-content">
          <ShortSummary />
          <div className="about-page__our-standards-desktop">
            <AlternatingPhotosGrid />
          </div>
          <p className="about-page__subtext">
            For a detailed list of our criteria, visit our{' '}
            <a href="https://help.goodeggs.com/hc/en-us/sections/360001324012-What-we-sell-and-why">
              Help Center
            </a>
            .
          </p>
        </div>
        <AboutNavList currentSlug="standards" />
      </AboutLayout>
    </MarketLayout>
  );
};

OurFoodPage.pageName = 'About';
OurFoodPage.reducer = (state, action) => {
  if (!state) throw new Error('State should always be preloaded here');
  return marketLayoutReducer(state, action);
};

export default OurFoodPage;
