import React, {FC, PropsWithChildren} from 'react';

import assetPath from 'web/helpers/asset_path';

export interface AlternatingPhotosRowProps {
  imagePath: string;
}

const AlternatingPhotosRow: FC<PropsWithChildren<AlternatingPhotosRowProps>> = ({
  imagePath,
  children,
}) => (
  <div className="alternating-photos-row">
    <div className="alternating-photos-row__content" data-testid="alternating-photos-row__content">
      {children}
    </div>
    <div
      className="alternating-photos-row__image"
      data-testid="alternating-photos-row__image"
      style={{backgroundImage: `url('${assetPath(imagePath)}?auto=format')`}}
    >
      {' '}
    </div>
    <div className="alternating-photos-row__content" data-testid="alternating-photos-row__content">
      {children}
    </div>
  </div>
);

export default AlternatingPhotosRow;
